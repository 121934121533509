export const FamilyHeaderMenuConfig = {
  items: [
    {
      title: 'Dashboard',
      root: true,
      alignment: 'left',
      page: '/family/dashboard',
    },
    {
      title: 'Payment Page',
      root: true,
      alignment: 'left',
      page: '/family/payment-page',
    },
    {
      title: 'Payment History',
      root: true,
      alignment: 'left',
      page: '/family/payment-history',
    },
    {
      title: 'Profile Page',
      root: true,
      alignment: 'left',
      page: '/family/profile-page',
    }
  ]
};
