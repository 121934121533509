export const CoachAsideMenuConfig = {
  items: [
    { section: 'Main' },
    {
      title: 'Dashboard',
      root: true,
      icon: 'ti-home',
      svg: './assets/media/svg/icons/Design/Layers.svg',
      page: '/coach/dashboard',
      bullet: 'dot',
    },
    {
      title: 'Payment Page',
      root: true,
      icon: 'ti-user',
      page: '/coach/payment-page',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Payment History',
      root: true,
      icon: 'ti-user',
      page: '/coach/payment-history',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Profile Page',
      root: true,
      icon: 'ti-user',
      page: '/coach/profile-page',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Manage Student',
      root: true,
      icon: 'ti-user',
      page: '/coach/manage-student',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Club Students Lists',
      root: true,
      icon: 'ti-bell',
      page: '/coach/club-students-lists',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Daily reports',
      root: true,
      icon: 'ti-user',
      page: '/coach/daily-reports',
      svg: './assets/media/svg/icons/Home/Library.svg'
    }
  ]
};