
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layouts/layout/layout.component';
import { TitleComponent } from './layouts/layout/title/title.component';
import { BreadcrumbsComponent } from './layouts/layout/breadcrumbs/breadcrumbs.component';
import { AuthComponent } from './layouts/auth/auth.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderMobileComponent } from './layouts/metronic/components/header-mobile/header-mobile.component';
import { AsideComponent } from './layouts/metronic/components/aside/aside.component';
import { AsideDynamicComponent } from './layouts/metronic/components/aside-dynamic/aside-dynamic.component';
import { NgbModule, NgbDatepickerI18n,  } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './layouts/metronic/components/footer/footer.component';
import { HeaderComponent } from './layouts/metronic/components/header/header.component';
import { HeaderMenuComponent } from './layouts/metronic/components/header/header-menu/header-menu.component';
import { HeaderMenuDynamicComponent } from './layouts/metronic/components/header/header-menu-dynamic/header-menu-dynamic.component';
import { TopbarComponent } from './layouts/metronic/components/topbar/topbar.component';
import { SubheaderModule } from './layouts/metronic/partials/layout/subheader/subheader.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { ScriptsInitComponent } from './layouts/metronic/init/scipts-init/scripts-init.component';
import { ExtrasModule } from './layouts/metronic/partials/layout/extras/extras.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LeaveGuard } from './leaved/leaved.guard';
import { TurkishDatePickerI18 } from './services/turkishDatePickerI18';

@NgModule({
  declarations: [
    AppComponent,
    ScriptsInitComponent,
    LayoutComponent,
    TitleComponent,
    BreadcrumbsComponent,
    AuthComponent,
    HeaderMobileComponent,
    AsideComponent,
    AsideDynamicComponent,
    FooterComponent,
    HeaderComponent,
    HeaderMenuComponent,
    HeaderMenuDynamicComponent,
    TopbarComponent,
  ],
  imports: [
    BrowserModule,
    InlineSVGModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    SubheaderModule,
    ExtrasModule,
    NgbModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [LeaveGuard,{provide: NgbDatepickerI18n, useClass: TurkishDatePickerI18}  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
