import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { UtilService } from './util.service';
export class AuthInfo {
	constructor(public $uid: string) { }

	isLoggedIn() {
		return !!this.$uid;
	}
}

@Injectable({
	providedIn: 'root'
})
export class ApisService {
	static UNKNOWN_USER = new AuthInfo(null);
	public authInfo$: BehaviorSubject<AuthInfo> = new BehaviorSubject<AuthInfo>(ApisService.UNKNOWN_USER);
	baseUrl: any = '';
	mediaURL: any = '';
	
	raceTypes = [
		{ id: "1", name: "World" },
		{ id: "2", name: "Olympic" },
		{ id: "3", name: "Continent" },
	];

	genders = [
		{ id: "1", name: "Kız" },
		{ id: "2", name: "Erkek" },
	];

	constructor(
		private http: HttpClient,
		private util: UtilService
	) {
		this.baseUrl = environment.baseURL;
		this.mediaURL = environment.mediaURL;
	}

	translate(str) {
		return str;
	}

	alerts(title, message, type) {
		Swal.fire(
			this.util.getString(title),
			this.util.getString(message),
			type
		);
	}

	uploadFile(files: File[], controller: any) {
		const formData = new FormData();
		Array.from(files).forEach(f => formData.append('userfile', f));
		return this.http.post(this.baseUrl + controller + '/upload_image', formData);
	}


	// uploadProductFile(files: File[]) {
	// 	const formData = new FormData();
	// 	Array.from(files).forEach(f => formData.append('userfile', f));
	// 	return this.http.post(this.baseUrl + 'product/upload_image', formData);
	//   }
	
	upload(files: File[], controller: any, filePath: any) {
		const formData = new FormData();
		Array.from(files).forEach(f => formData.append('userfile', f));
		formData.append('path', filePath);
		return this.http.post(this.baseUrl + controller + '/upload_file', formData);
	}

	getCurrencyCode() {
		return environment.general.code;
	}

	getCurrecySymbol() {
		return environment.general.symbol;
	}

	JSON_to_URLEncoded(element, key?, list?) {
		let new_list = list || [];
		if (typeof element === 'object') {
			for (let idx in element) {
				this.JSON_to_URLEncoded(
					element[idx],
					key ? key + '[' + idx + ']' : idx,
					new_list
				);
			}
		} else {
			new_list.push(key + '=' + encodeURIComponent(element));
		}
		return new_list.join('&');
	}

	public post(url, body): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			const header = {
				headers: new HttpHeaders()
					.set('Content-Type', 'application/x-www-form-urlencoded')
					.set('Basic', `${environment.authToken}`)
			};
			const param = this.JSON_to_URLEncoded(body);
			this.http.post(this.baseUrl + url, param, header).subscribe((data) => {
				resolve(data);
			}, error => {
				resolve(error);
			});
			// return this.http.post(this.baseUrl + url, param, header);
		});
	}

	public get(url): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			const header = {
				headers: new HttpHeaders()
					.set('Content-Type', 'application/x-www-form-urlencoded')
					.set('Basic', `${environment.authToken}`)
				// .set('responseType', 'blob')
			};
			this.http.get(this.baseUrl + url, header).subscribe((data) => {
				resolve(data);
			}, error => {
				resolve(error);
			});
		});
	}

	public externalGet(url): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			const header = {
				headers: new HttpHeaders()
					.set('Content-Type', 'application/x-www-form-urlencoded')
					.set('Basic', `${environment.authToken}`)
			};
			this.http.get(url, header).subscribe((data) => {
				resolve(data);
			}, error => {
				resolve(error);
			});
		});
	}

	sendNotification(msg, title) {
		const body = {
		  app_id: environment.onesignal.appId,
		  included_segments: ['Active Users', 'Inactive Users"'],
		  headings: { en: title },
		  contents: { en: msg },
		  data: { task: msg }
		};
		const header = {
		  headers: new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', `Basic ${environment.onesignal.restKey}`)
		};
		return this.http.post('https://onesignal.com/api/v1/notifications', body, header);
	  }
	
	  sendNotification2(msg, title, id) {
		const body = {
		  app_id: environment.onesignal.appId,
		  include_player_ids: [id],
		  headings: { en: title },
		  contents: { en: msg },
		  data: { task: msg }
		};
		const header = {
		  headers: new HttpHeaders()
			.set('Content-Type', 'application/json')
			.set('Authorization', `Basic ${environment.onesignal.restKey}`)
		};
		return this.http.post('https://onesignal.com/api/v1/notifications', body, header);
	  }
}
