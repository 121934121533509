<ng-container>
  <!--begin::Nav-->
  <div class="navi navi-spacer-x-0 pt-5">
    <div class="navi-footer px-8 py-5">
      <a class="btn btn-light-primary font-weight-bold cursor-pointer"  (click)="change_password()">{{util.getString('Change Passsword')}}</a>
      <a class="btn btn-light-primary font-weight-bold cursor-pointer"  (click)="logout()">{{util.getString('Sign Out')}}</a>
    </div>
    <!--end::Footer-->
  </div>
  <!--end::Nav-->
</ng-container>
