import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdminAsideMenuConfig } from '../../configs/admin-aside-menu.config';
import { StudentAsideMenuConfig } from '../../configs/student-aside-menu.config';
import { ClubAsideMenuConfig } from '../../configs/club-aside-menu.config';
import { CoachAsideMenuConfig } from '../../configs/coach-aside-menu.config';
import { OtherAsideMenuConfig } from '../../configs/other-aside-menu.config';
import { FamilyAsideMenuConfig } from '../../configs/family-aside-menu.config';

const emptyMenuConfig = {
  items: []
};

@Injectable({
  providedIn: 'root'
})
export class DynamicAsideMenuService {
  private menuConfigSubject = new BehaviorSubject<any>(emptyMenuConfig);
  menuConfig$: Observable<any>;
  user: any = "";
  admin: any = "";
  constructor() {
    this.menuConfig$ = this.menuConfigSubject.asObservable();
    this.admin = localStorage.getItem('admin');
    this.user = localStorage.getItem('user');
    this.loadMenu();
  }

  // Here you able to load your menu from server/data-base/localStorage
  // Default => from DynamicAsideMenuConfig
  private loadMenu() {
    if (this.admin && this.admin != '') {
      let temp = { items: [] };
      let admin = JSON.parse(this.admin);
      let tempIndex = 0;
      let addsection = 0;
      let count = 0;
      if (admin.permission && admin.permission != '') {
        let pages = JSON.parse(admin.permission);
        for (let i = 0; i < AdminAsideMenuConfig.items.length; i++) {
          if (AdminAsideMenuConfig.items[i].section) {
            temp.items[tempIndex] = AdminAsideMenuConfig.items[i];
            tempIndex ++;
            addsection++;
            continue;
          }
          for (let j = 0; j < pages.length; j++) {
            if ('/' + pages[j].main_state + '/' + pages[j].state == AdminAsideMenuConfig.items[i].page) {
              temp.items[tempIndex] = AdminAsideMenuConfig.items[i];
              tempIndex ++;
              count++;
            }
          }
          if (count == 0 && addsection > 0) {
            temp.items.splice(tempIndex-1, 1);
            tempIndex --;
          } 
          count = 0;
          addsection = 0;
        }
      } else {
        temp.items = AdminAsideMenuConfig.items;
      }
      this.setMenu(temp);
    } else if (this.user && this.user != '') {
      switch (parseInt(JSON.parse(this.user).student_type)) {
        case 1: this.setMenu(StudentAsideMenuConfig); break;
        case 2: this.setMenu(FamilyAsideMenuConfig); break;
        case 3: this.setMenu(CoachAsideMenuConfig); break;
        case 4: this.setMenu(ClubAsideMenuConfig); break;
        case 5: this.setMenu(OtherAsideMenuConfig); break;
      }
    }
  }

  private setMenu(menuConfig) {
    this.menuConfigSubject.next(menuConfig);
  }

  private getMenu(): any {
    return this.menuConfigSubject.value;
  }
}
