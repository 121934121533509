import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LayoutService } from '../../../../../core';
import { Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
@Component({
  selector: 'app-user-dropdown-inner',
  templateUrl: './user-dropdown-inner.component.html',
  styleUrls: ['./user-dropdown-inner.component.scss'],
})
export class UserDropdownInnerComponent implements OnInit {
  extrasUserDropdownStyle: 'light' | 'dark' = 'light';
  user: any = "";
  admin: any = "";

  constructor(private layout: LayoutService,private router: Router,public util: UtilService) {
    this.user = localStorage.getItem('user');
    this.admin = localStorage.getItem('admin');
  }

  ngOnInit(): void {
    this.extrasUserDropdownStyle = this.layout.getProp(
      'extras.user.dropdown.style'
    );
    
  }

  logout() {
    const lng = localStorage.getItem('lng');
		localStorage.clear();
		localStorage.setItem('lng', lng);
		//this.router.navigate(['']);
    document.location.reload();
  }

  change_password() {
    if ( this.admin && this.admin != "") {
      this.router.navigate(['admin/change-password']);
    } else if (this.user && this.user != ""){
      switch (parseInt(JSON.parse(this.user).student_type)) {
        case 1: this.router.navigate(['student/change-password']); break;
        case 2: this.router.navigate(['family/change-password']); break;
        case 3: this.router.navigate(['coach/change-password']); break;
        case 4: this.router.navigate(['club/change-password']); break;
        case 5: this.router.navigate(['other/change-password']); break;
      }
    }
  }
}
