
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //baseURL: 'http://localhost/ikaeEpos/ikaepos-api/index.php/',
 	//mediaURL: 'http://localhost/ikaeEpos/ikaepos-api/uploads/',
  appVersion: 'v717demo1',
  apiUrl: 'api',
  baseURL: 'https://www.ikaepos.com/api/index.php/',
  mediaURL: 'https://www.ikaepos.com/api/uploads/',
  onesignal: {
    appId: '94aa0c12-9629-43fd-86e3-729f79849bc4',
    googleProjectNumber: '411673610622',
    restKey: 'N2UxMWMzMzUtYTA1YS00MDBjLWI2MTMtNmRiODIyYmIyZTBk'
  },
  general: {
    symbol: '$',
    code: 'USD'
  },
  authToken: 'YmQ4OWYwNTEtZjlkMy00NzhhLTkyY2UtN2MwZWY0YjYyNTFm',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
