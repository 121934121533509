import { Injectable } from '@angular/core';

export interface BadgeItem {
	type: string;
	value: string;
}

export interface ChildrenItems {
	state: string;
	target?: boolean;
	name: string;
	type?: string;
	children?: ChildrenItems[];
}

export interface MainMenuItems {
	state: string;
	main_state?: string;
	target?: boolean;
	name: string;
	type: string;
	icon: string;
	badge?: BadgeItem[];
	children?: ChildrenItems[];
}

export interface Menu {
	label: string;
	main: MainMenuItems[];

}

const ADMINMENUITEMS = [
	{
		label: "Main",
		icon: 'ti-home',
		main: [
			{
				state: 'dashboard',
				main_state: 'admin',
				name: 'Dashboard',
				type: 'link',
				icon: 'ti-home'
			},
						{
				state: 'categories',
				main_state: 'admin',
				name: 'Categories',
				type: 'link',
				icon: 'ti-view-grid'
			},
			{
				state: 'products',
				main_state: 'admin',
				name: 'Products',
				type: 'link',
				icon: 'ti-package'
			},
			{
				state: 'companies',
				main_state: 'admin',
				name: 'Companies',
				type: 'link',
				icon: 'ti-package'
			},
			{
				state: 'company-stats',
				name: 'Company Stats',
				type: 'link',
				icon: 'ti-bar-chart'
			},
			{
				state: 'orders',
				main_state: 'admin',
				name: 'Orders',
				type: 'link',
				icon: 'ti-shopping-cart-full'
			},
			{
				state: 'all-users',
				main_state: 'admin',
				name: 'Users',
				type: 'link',
				icon: 'ti-user'
			},
			{
				state: 'all-news',
				main_state: 'admin',
				name: 'News',
				type: 'link',
				icon: 'ti-rss-alt'
			},
			{
				state: 'all-pages',
				main_state: 'admin',
				name: 'Pages',
				type: 'link',
				icon: 'ti-file'
			},
			{
				state: 'banners',
				main_state: 'admin',
				name: 'Banners',
				type: 'link',
				icon: 'ti-image'
			},
			{
				state: 'sku-code',
				main_state: 'admin',
				name: 'Su Code',
				type: 'link',
				icon: 'ti-crown'
			},
			{
				state: 'all-videos',
				main_state: 'admin',
				name: 'Videos',
				type: 'link',
				icon: 'ti-video-camera'
			},
		]
	},
	{
		label: 'Others',
		main: [
			{
				state: 'all-supports',
				main_state: 'admin',
				name: 'Supports',
				type: 'link',
				icon: 'ti-headphone-alt'
			},
			{
				state: 'support-subject',
				main_state: 'admin',
				name: 'Support Subjects',
				type: 'link',
				icon: 'ti-list'
			},
			{
				state: 'all-notifications',
				main_state: 'admin',
				name: 'Notifications',
				type: 'link',
				icon: 'ti-announcement'
			},
			{
				main_state: 'admin',
				state: 'sales-forms',
				name: 'Sales Forms',
				type: 'link',
				icon: 'ti-receipt'
			},
			{
				main_state: 'admin',
				state: 'send-mail',
				name: 'Send Mail',
				type: 'link',
				icon: 'ti-email'
			},
			{
				state: 'settings',
				main_state: 'admin',
				name: 'Settings',
				type: 'link',
				icon: 'ti-settings'
			}
		]
	},


];

@Injectable()
export class AdminMenuItems {
	getAll(): Menu[] {
		return ADMINMENUITEMS;
	}

	/*add(menu: Menu) {
		MENUITEMS.push(menu);
	}*/
}
