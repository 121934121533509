import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApisService } from '../services/apis.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(private authServ: ApisService, private router: Router) { }
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
		const admin = localStorage.getItem('admin');
		let urlArr = state.url.split('/');
		if (urlArr.length > 3) {
			if (urlArr[1] == "admin") {
				return true;
			}
			state.url = "/" + urlArr[1];
		}
		if (( state.url.indexOf('admin') > 0 && state.url.indexOf('change-password') > 0)) {
			if (admin && admin != null && admin !== 'null') {
				return true;
			} else {
				this.router.navigate(['/admin/dashboard']);
				return false;
			}
		}
		if (state.url.indexOf('admin') > 0) {
			if (admin && admin != null && admin !== 'null') {
				let permissions = JSON.parse(admin).permission;
				if (permissions && permissions != '') {
					permissions = JSON.parse(permissions);
					let i;
					for (i = 0; i < permissions.length; i++) {
						if ('/' + permissions[i].main_state + '/' + permissions[i].state == state.url) {
							break;
						}
					};
					if (i < permissions.length)
						return true;
					else {
						this.router.navigate(['/admin/dashboard']);
						return false;
					}
				}
				return true;
			}
			else {
				this.router.navigate(['/login']);
			}
			return false;
		}

		this.router.navigate(['/login']);
		return false;
	}
}
