export const ClubHeaderMenuConfig = {
  items: [
    {
      title: 'Dashboard',
      root: true,
      alignment: 'left',
      page: '/club/dashboard',
    },
    {
      title: 'Formal Students',
      root: true,
      alignment: 'left',
      page: '/club/student-list',
    },
    {
      title: 'Informal Students',
      root: true,
      alignment: 'left',
      page: '/club/informal-students',
    },
    {
      title: 'More',
      bullet: 'dot',
      icon: 'flaticon-web',
      mega: true,
      submenu: [
        {
          title: '',
          submenu: [
            {
              title: 'Club Coach',
              page: '/club/club-coach'
            },
            {
              title: 'Loan Lists',
              page: '/club/loan-lists'
            },
            {
              title: 'Swimmer Statics',
              page: '/club/swimmer-statics'
            },
            {
              title: 'Payment Page',
              page: '/club/payment-page'
            },
            {
              title: 'Payment History',
              page: '/club/payment-history'
            }
          ]
        }
      ]
    }
  ]
};
