export const AdminAsideMenuConfig = {
  items: [
    { section: 'Main' },
    {
      title: 'Dashboard',
      root: true,
      icon: 'ti-home',
      svg: './assets/media/svg/icons/Design/Layers.svg',
      page: '/admin/dashboard',
      bullet: 'dot',
    },
    {
      title: 'Categories',
      root: true,
      icon: 'ti-view-grid',
      page: '/admin/categories',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Products',
      root: true,
      icon: 'ti-package',
      page: '/admin/products',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Orders',
      root: true,
      icon: 'ti-shopping-cart-full',
      page: '/admin/orders',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Users',
      root: true,
      icon: 'ti-user',
      page: '/admin/all-users',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
		{
      title: 'Companies',
      root: true,
      icon: 'ti-briefcase',
      page: '/admin/companies',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
		{
      title: 'Company Stats',
      root: true,
      icon: 'ti-bar-chart',
      page: '/admin/company-stats',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'News',
      root: true,
      icon: 'ti-rss-alt',
      page: '/admin/all-news',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Pages',
      root: true,
      icon: 'ti-file',
      page: '/admin/all-pages',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Banners',
      root: true,
      icon: 'ti-image',
      page: '/admin/banners',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Su Code',
      root: true,
      icon: 'ti-crown',
      page: '/admin/sku-code',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Videos',
      root: true,
      icon: 'ti-video-camera',
      page: '/admin/all-videos',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    { section: 'Others' },
    {
      title: 'Supports',
      root: true,
      icon: 'ti-headphone-alt',
      page: '/admin/all-supports',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Support Subjects',
      root: true,
      icon: 'ti-list',
      page: '/admin/support-subject',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Notifications',
      root: true,
      icon: 'ti-announcement',
      page: '/admin/all-notifications',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Sales Forms',
      root: true,
      icon: 'ti-receipt',
      page: '/admin/sales-forms',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Send Mail',
      root: true,
      icon: 'ti-email',
      page: '/admin/send-mail',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Settings',
      root: true,
      icon: 'ti-settings',
      page: '/admin/settings',
      svg: './assets/media/svg/icons/Home/Library.svg'
    }
  ]
};