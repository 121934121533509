export const CoachHeaderMenuConfig = {
  items: [
    {
      title: 'Dashboard',
      root: true,
      alignment: 'left',
      page: '/coach/dashboard',
    },
    {
      title: 'Payment Page',
      root: true,
      alignment: 'left',
      page: '/coach/payment-page',
    },
    {
      title: 'Payment History',
      root: true,
      alignment: 'left',
      page: '/coach/payment-history',
    },
    {
      title: 'More',
      bullet: 'dot',
      icon: 'flaticon-web',
      mega: true,
      submenu: [
        {
          title: '',
          submenu: [
            {
              title: 'Profile Page',
              page: '/coach/profile-page'
            },
            {
              title: 'Manage Student',
              page: '/coach/manage-student'
            },
            {
              title: 'Club Students Lists',
              page: '/coach/club-students-lists'
            },
            {
              title: 'Daily reports',
              page: '/coach/daily-reports'
            }
          ]
        }
      ]
    }
  ]
};
