<ng-container>
  <ng-container>
    <!--begin::User-->
    <ng-container>
      <div class="dropdown" ngbDropdown placement="bottom-right">
        <div class="topbar-item" data-toggle="dropdown" data-offset="0px,0px" ngbDropdownToggle>
          <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2" *ngIf="admin">
            <img style="height: 100%;" [src]="admin.cover" alt="User-Profile-Image" onError="this.src='assets/images/user.png'">
            <span>&nbsp;&nbsp;{{admin.first_name}}</span>
            <i class="ti-angle-down"></i>
          </div>
          <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2" *ngIf="user">
            <img style="height: 100%;" [src]="user.img_url" alt="User-Profile-Image" onError="this.src='assets/images/user.png'">
            <span>&nbsp;&nbsp;{{user.first_name}}</span>
            <i class="ti-angle-down"></i>
          </div>
        </div>
        <!--end::Toggle-->
        <!--begin::Dropdown-->
        <div ngbDropdownMenu
          class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
          <app-user-dropdown-inner></app-user-dropdown-inner>
        </div>
      </div>
      <!--end::Dropdown-->
    </ng-container>
    <!--end::User-->
  </ng-container>
</ng-container>

<!--end::Topbar-->