import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { LayoutService } from '../../../../metronic/core';
import { AdminMenuItems } from '../../../../../shared/menu-items/admin-menu-items';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UtilService } from 'src/app/services/util.service';
function getCurrentURL(location) {
  return location.split(/[?#]/)[0];
}

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {
  ulCSSClasses: string;
  rootArrowEnabled: boolean;
  location: Location;
  headerMenuDesktopToggle: string;
  menus: any = [];
  tmpMenus: any = [];
  currentUrl: string;
  constructor(
    private layout: LayoutService,
    private loc: Location,
    public adminMenuItems: AdminMenuItems,
    public util: UtilService,
    private router: Router,
  ) {
    this.location = this.loc;
    var self = this;
    let admin: any = localStorage.getItem('admin');
    if (admin && admin != '') {
      admin = JSON.parse(admin);
      if (admin.permission && admin.permission != '') {
        let pages = JSON.parse(admin.permission);
        this.adminMenuItems.getAll().forEach(function (menu: any) {
          let newmenu: any = { label: menu.label, main: [] };
          menu.main.forEach(function (submenu) {
            for (let i = 0; i < pages.length; i++) {
              if (submenu.state == pages[i].state && submenu.main_state == pages[i].main_state) {
                newmenu.main.push(submenu);
              }
            }

            if (submenu.children) {////console.log(JSON.stringify(submenu));
              let newsubmenu: any = JSON.parse(JSON.stringify(submenu));
              newsubmenu.children = [];
              submenu.children.forEach(child => {
                for (let i = 0; i < pages.length; i++) {
                  if (submenu.state == pages[i].main_state && child.state == pages[i].state) {
                    newsubmenu.children.push(child);
                  }
                }
              });
              if (newsubmenu.children.length > 0) {
                newmenu.main.push(submenu);
              }
            }
          });
          if (newmenu.main.length > 0) {
            self.menus.push(newmenu);
          }
        });
        self.tmpMenus = self.menus;
        self.menus = [];
        for (let i = 0; i < self.tmpMenus.length; i++) {
          for (let j = 0; j < self.tmpMenus[i].main.length; j ++) {
            self.menus.push(self.tmpMenus[i].main[j]);
          }
        }
      }
    }
    this.currentUrl = this.router.url.split(/[?#]/)[0];
    const routerSubscr = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentUrl = event.url;
    });
  }

  ngOnInit(): void {
    this.ulCSSClasses = this.layout.getStringCSSClasses('header_menu_nav');
    this.rootArrowEnabled = this.layout.getProp('header.menu.self.rootArrow');
    this.headerMenuDesktopToggle = this.layout.getProp(
      'header.menu.desktop.toggle'
    );
  }

  getMenuItemActive(url) {
    return this.checkIsActive(url) ? 'menu-item-active' : '';
  }

  isMenuItemActive(path) {
    if (!this.currentUrl || !path) {
      return false;
    }

    if (this.currentUrl === path) {
      return true;
    }

    if (this.currentUrl.indexOf(path) > -1) {
      return true;
    }

    return false;
  }

  checkIsActive(url) {
    const location = this.location.path();
    const current = getCurrentURL(location);
    if (!current || !url) {
      return false;
    }

    if (current === url) {
      return true;
    }

    if (current.indexOf(url) > -1) {
      return true;
    }

    return false;
  }
}
