export const OtherAsideMenuConfig = {
  items: [
    { section: 'Main' },
    {
      title: 'Dashboard',
      root: true,
      icon: 'ti-home',
      svg: './assets/media/svg/icons/Design/Layers.svg',
      page: '/other/dashboard',
      bullet: 'dot',
    },
    {
      title: 'Payment Page',
      root: true,
      icon: 'ti-user',
      page: '/other/payment-page',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Payment History',
      root: true,
      icon: 'ti-user',
      page: '/other/payment-history',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Profile Page',
      root: true,
      icon: 'ti-user',
      page: '/other/profile-page',
      svg: './assets/media/svg/icons/Home/Library.svg'
    }
  ]
};