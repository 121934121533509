export const FamilyAsideMenuConfig = {
  items: [
    { section: 'Main' },
    {
      title: 'Dashboard',
      root: true,
      icon: 'ti-home',
      svg: './assets/media/svg/icons/Design/Layers.svg',
      page: '/family/dashboard',
      bullet: 'dot',
    },
    {
      title: 'Payment Page',
      root: true,
      icon: 'ti-home',
      page: '/family/payment-page',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Payment History',
      root: true,
      icon: 'ti-home',
      page: '/family/payment-history',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Profile Page',
      root: true,
      icon: 'ti-home',
      page: '/family/profile-page',
      svg: './assets/media/svg/icons/Home/Library.svg'
    }
  ]
};