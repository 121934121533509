import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastData, ToastOptions } from 'ng2-toasty';


@Injectable({
	providedIn: 'root'
})
export class UtilService {
	report: any;
	public translations: any[] = [];
	private ejectMessages = new Subject<any>();

	constructor(
		
	) { }

	setReport(data) {
		this.report = data;
	}

	ejectMsg() {
		this.ejectMessages.next();
	}

	successEject(): Subject<any> {
		return this.ejectMessages;
	}

	getReport() {
		return this.report;
	}

	getCurrencyCode() {
		return environment.general.code;
	}

	getCurrecySymbol() {
		return environment.general.symbol;
	}

	showToast(toastyService, title, message = null) {
		if(!message)
			message = 'Something went wrong';

		const toastOptions: ToastOptions = {
			title: this.getString(title),
			msg: this.getString(message),
			showClose: true,
			timeout: 2000,
			theme: 'default',
			onAdd: (toast: ToastData) => {
			},
			onRemove: () => {
			}
		};
		// Add see all possible types in one shot
		toastyService.error(toastOptions);
	}

	getString(str) {
		if (this.translations[str]) {
			return this.translations[str];
		}
		return str;
	}
}
