<!--begin::Container-->
<div
  [ngClass]="footerContainerCSSClasses"
  class="d-flex flex-column flex-md-row align-items-center justify-content-between"
>
  <!--begin::Copyright-->
  <!--div class="text-dark order-2 order-md-1">
    <span class="text-muted font-weight-bold mr-2"
      >{{ currentYear }} &copy;</span
    >
    <a
      href=""
      target="_blank"
      class="text-dark-75 text-hover-primary"
      >SwimRank</a
    >
  </div-->
  <!--end::Copyright-->

  <!--begin::Nav-->
  <div class="nav nav-dark">
    <span class="nav-link pl-0 pr-5"
    >{{ currentYear }} &copy;</span
  >
  <a
    href=""
    target="_blank"
    class="nav-link pl-0 pr-5 text-hover-primary"
    >IkaePos</a
  >
    <!-- <a
      [href]="role + '/privacy-policy'"
      target="_blank"
      class="nav-link pl-0 pr-5"
      *ngIf="flag"
      >{{util.getString('Privacy Policy')}}</a
    >
    <a
    [href]="role + '/terms-condition'"
      target="_blank"
      class="nav-link pl-0 pr-5"
      *ngIf="flag"
      >{{util.getString('Terms and Conditions')}}</a
    >
    <a
    [href]="role + '/kvkk'"
      target="_blank"
      class="nav-link pl-0 pr-5"
      *ngIf="flag"
      >{{util.getString('KVKK')}}</a
    >
    <a
    [href]="role + '/about-us'"
      target="_blank"
      class="nav-link pl-0 pr-0"
      *ngIf="flag"
      >{{util.getString('About Us')}}</a
    > -->
    <!-- <a href="https://www.iyzico.com/"
    target="_blank"
    class="nav-link pl-0 pr-0 px-6">
      <img alt="" src="../../../../assets/icons/iyzico.png"
      width="250">
    </a> -->
    <!--a href=""
    target="_blank"
    class="nav-link pl-0 pr-0 px-6">
      <img alt="" src="../../../../assets/icons/visa.png"
      width="60">
    </a-->
  </div>
  <!--end::Nav-->
</div>
<!--end::Container-->
