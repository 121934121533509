import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../../metronic/core';
import { AdminMenuItems } from '../../../../shared/menu-items/admin-menu-items';
import { UtilService } from 'src/app/services/util.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
})
export class AsideComponent implements OnInit {
  disableAsideSelfDisplay: boolean;
  headerLogo: string;
  brandSkin: string;
  ulCSSClasses: string;
  location: Location;
  asideMenuHTMLAttributes: any = {};
  asideMenuCSSClasses: string;
  asideMenuDropdown;
  brandClasses: string;
  asideMenuScroll = 1;
  asideSelfMinimizeToggle = false;
  menus: any = [];
  currentUrl: string;


  constructor(
    private layout: LayoutService,
    private loc: Location,
    public adminMenuItems: AdminMenuItems,
    public util: UtilService,
    private router: Router,
  ) {
    var self = this;
    let admin: any = localStorage.getItem('admin');
    if (admin && admin != '') {
      admin = JSON.parse(admin);
      if (admin.permission && admin.permission != '') {
        let pages = JSON.parse(admin.permission);
        this.adminMenuItems.getAll().forEach(function (menu: any) {
          let newmenu: any = { label: menu.label, main: [] };
          menu.main.forEach(function (submenu) {
            for (let i = 0; i < pages.length; i++) {
              if (submenu.state == pages[i].state && submenu.main_state == pages[i].main_state) {
                newmenu.main.push(submenu);
              }
            }

            if (submenu.children) {////console.log(JSON.stringify(submenu));
              let newsubmenu: any = JSON.parse(JSON.stringify(submenu));
              newsubmenu.children = [];
              submenu.children.forEach(child => {
                for (let i = 0; i < pages.length; i++) {
                  if (submenu.state == pages[i].main_state && child.state == pages[i].state) {
                    newsubmenu.children.push(child);
                  }
                }
              });
              if (newsubmenu.children.length > 0) {
                newmenu.main.push(submenu);
              }
            }
          });
          if (newmenu.main.length > 0) {
            self.menus.push(newmenu);
          }
        });
      }
    }
    this.currentUrl = this.router.url.split(/[?#]/)[0];
    const routerSubscr = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.currentUrl = event.url;
    });
  }

  ngOnInit(): void {
    // load view settings
    this.disableAsideSelfDisplay =
      this.layout.getProp('aside.self.display') === false;
    this.brandSkin = this.layout.getProp('brand.self.theme');
    this.headerLogo = this.getLogo();
    this.ulCSSClasses = this.layout.getProp('aside_menu_nav');
    this.asideMenuCSSClasses = this.layout.getStringCSSClasses('aside_menu');
    this.asideMenuHTMLAttributes = this.layout.getHTMLAttributes('aside_menu');
    this.asideMenuDropdown = this.layout.getProp('aside.menu.dropdown') ? '1' : '0';
    this.brandClasses = this.layout.getProp('brand');
    this.asideSelfMinimizeToggle = this.layout.getProp(
      'aside.self.minimize.toggle'
    );
    this.asideMenuScroll = this.layout.getProp('aside.menu.scroll') ? 1 : 0;
    // this.asideMenuCSSClasses = `${this.asideMenuCSSClasses} ${this.asideMenuScroll === 1 ? 'scroll my-4 ps ps--active-y' : ''}`;
    // Routing
    this.location = this.loc;
  }

  getName(name) {
    // return this.api.translate(name);
    return name;
  }

  isMenuItemActive(path) {
    if (!this.currentUrl || !path) {
      return false;
    }

    if (this.currentUrl === path) {
      return true;
    }

    if (this.currentUrl.indexOf(path) > -1) {
      return true;
    }

    return false;
  }

  private getLogo() {
    if (this.brandSkin === 'light') {
      return './assets/logo.png';
    } else {
      return './assets/logo.png';
    }
  }
}
