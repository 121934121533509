export const AdminHeaderMenuConfig = {
  items: [
    {
      title: 'Dashboard',
      root: true,
      alignment: 'left',
      page: '/admin/dashboard',
    },
    {
      title: 'Users',
      root: true,
      alignment: 'left',
      page: '/admin/all-users',
    },
    {
      title: 'Products',
      root: true,
      alignment: 'left',
      page: '/admin/products',
    },
    {
      title: 'Categories',
      root: true,
      alignment: 'left',
      page: '/admin/categories',
    },
    {
      title: 'Pages',
      root: true,
      alignment: 'left',
      page: '/admin/all-pages',
    },
    {
      title: 'Banners',
      root: true,
      alignment: 'left',
      page: '/admin/banners',
    },
    {
      title: 'More',
      bullet: 'dot',
      icon: 'flaticon-web',
      mega: true,
      submenu: [
  
        {
          title: 'News & Blog & Supports & Notifications',
          submenu: [
            {
              title: 'News',
              page: '/admin/all-news'
            },
            {
              title: 'Su Code',
              page: '/admin/sku-code'
            },
            {
              title: 'Videos',
              page: '/admin/all-videos'
            },
            {
              title: 'Supports',
              page: '/admin/all-supports'
            },
            {
              title: 'Support Subjects',
              page: '/admin/support-subject'
            },
            {
              title: 'Notifications',
              page: '/admin/all-notifications'
            },
            {
              title: 'Sales Forms',
              page: '/admin/sales-forms'
            },
            {
              title: 'Send Mail',
              page: '/admin/send-mail'
            },
            {
              title: 'Settings',
              page: '/admin/settings'
            }
          ]
        }
      ]
    }
  ]
};
