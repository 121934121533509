import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UtilService } from 'src/app/services/util.service';
import { ApisService } from 'src/app/services/apis.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'app';

	constructor(
		private router: Router,
		public util: UtilService,
		public api: ApisService,
	) {
		//this.initializeApp();
	}

	initializeApp() {
		// const param = {
		// 	id: "en.json" // english 
		// 	id: "tr.json" // turkish
		// };
		// this.api.post('customer/getDefaultSettings', '').then((data: any) => {
		// 	if (data && data.status === 200 && data.data) {
		// 		const language = data.data.lang;
		// 		if (language) {
		// 			this.util.translations = language;
		// 		}
		// 	}
		// }, error => {
		// });
	}

	ngOnInit() {
		this.router.events.subscribe((evt) => {
			if (!(evt instanceof NavigationEnd)) {
				return;
			}
			window.scrollTo(0, 0);
		});
	}
}
