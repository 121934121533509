import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdminHeaderMenuConfig } from '../../configs/admin-header-menu.config';
import { StudentHeaderMenuConfig } from '../../configs/student-header-menu.config';
import { OtherHeaderMenuConfig } from '../../configs/other-header-menu.config';
import { ClubHeaderMenuConfig } from '../../configs/club-header-menu.config';
import { CoachHeaderMenuConfig } from '../../configs/coach-header-menu.config';
import { FamilyHeaderMenuConfig } from '../../configs/family-header-menu.config';

const emptyMenuConfig = {
  items: []
};

@Injectable({
  providedIn: 'root'
})
export class DynamicHeaderMenuService {
  private menuConfigSubject = new BehaviorSubject<any>(emptyMenuConfig);
  menuConfig$: Observable<any>;
  user: any = "";
  admin: any = "";
  constructor() {
    this.menuConfig$ = this.menuConfigSubject.asObservable();
    this.admin = localStorage.getItem('admin');
    this.user = localStorage.getItem('user');
    this.loadMenu();
  }

  // Here you able to load your menu from server/data-base/localeStorage
  // Default => from DynamicHeaderMenuConfig
  private loadMenu() {
    if (this.admin && this.admin != '') {
      let adminheaderMenu = AdminHeaderMenuConfig;
      let temp = { items: [] };
      let admin = JSON.parse(this.admin);
      let tempIndex = 0;
      let submenu1 = { title: '', bullet: '', icon: '', mega: true, submenu: [] };
      let submenu2 = { title: '', submenu: [] };
      let submenu3 = [];
      if (admin.permission && admin.permission != '') {
        let pages = JSON.parse(admin.permission);
        for (let i = 0; i < adminheaderMenu.items.length; i++) {
          if (adminheaderMenu.items[i].root) {
            for (let j = 0; j < pages.length; j++) {
              if (adminheaderMenu.items[i].page == '/' + pages[j].main_state + '/' + pages[j].state) {
                temp.items[tempIndex] = adminheaderMenu.items[i];
                tempIndex++;
              }
            }
          }
          if (adminheaderMenu.items[i].mega) {
            submenu1.title = adminheaderMenu.items[i].title;
            submenu1.bullet = adminheaderMenu.items[i].bullet;
            submenu1.icon = adminheaderMenu.items[i].icon;
            submenu1.mega = adminheaderMenu.items[i].mega;
            for (let k = 0; k < adminheaderMenu.items[i].submenu.length; k++) {
              if (adminheaderMenu.items[i].submenu[k].submenu) {
                submenu2.title = adminheaderMenu.items[i].submenu[k].title;
                for (let l = 0; l < adminheaderMenu.items[i].submenu[k].submenu.length; l++) {
                  for (let j = 0; j < pages.length; j++) {
                    if (adminheaderMenu.items[i].submenu[k].submenu[l].page == '/' + pages[j].main_state + '/' + pages[j].state) {
                      submenu3.push(adminheaderMenu.items[i].submenu[k].submenu[l]);
                    }
                  }
                }
                if (submenu3.length > 0) {
                  submenu2.submenu = submenu3;
                  submenu3 = [];
                }
                if (submenu2.submenu.length > 0) {
                  submenu1.submenu.push(submenu2);
                  submenu2 = { title: '', submenu: [] };
                }
              }
            }
            if (submenu1.submenu.length > 0) {
              temp.items[tempIndex] = submenu1;
              tempIndex++;
              submenu1 = { title: '', bullet: '', icon: '', mega: true, submenu: [] };
            }
          }
        }
      } else {
        temp.items = AdminHeaderMenuConfig.items;
      }
      this.setMenu(temp);
    } else if (this.user && this.user != '') {
      switch (parseInt(JSON.parse(this.user).student_type)) {
        case 1: this.setMenu(StudentHeaderMenuConfig); break;
        case 2: this.setMenu(FamilyHeaderMenuConfig); break;
        case 3: this.setMenu(CoachHeaderMenuConfig); break;
        case 4: this.setMenu(ClubHeaderMenuConfig); break;
        case 5: this.setMenu(OtherHeaderMenuConfig); break;
      }
    }
  }

  private setMenu(menuConfig) {
    this.menuConfigSubject.next(menuConfig);
  }

  private getMenu(): any {
    return this.menuConfigSubject.value;
  }
}
