export const ClubAsideMenuConfig = {
  items: [
    { section: 'Main' },
    {
      title: 'Dashboard',
      root: true,
      icon: 'ti-home',
      svg: './assets/media/svg/icons/Design/Layers.svg',
      page: '/club/dashboard',
      bullet: 'dot',
    },
    {
      title: 'Formal Students',
      root: true,
      icon: 'ti-user',
      page: '/club/student-list',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Informal Students',
      root: true,
      icon: 'ti-user',
      page: '/club/informal-students',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Loan Lists',
      root: true,
      icon: 'ti-home',
      page: '/club/loan-lists',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Swimmer Statics',
      root: true,
      icon: 'ti-user',
      page: '/club/swimmer-statics',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Payment Page',
      root: true,
      icon: 'ti-bell',
      page: '/club/payment-page',
      svg: './assets/media/svg/icons/Home/Library.svg'
    },
    {
      title: 'Payment History',
      root: true,
      icon: 'ti-home',
      page: '/club/payment-history',
      svg: './assets/media/svg/icons/Home/Library.svg'
    }
  ]
};