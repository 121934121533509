import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layouts/layout/layout.component';
import { AuthComponent } from './layouts/auth/auth.component';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'admin/dashboard',
		pathMatch: 'full'
	},
	{
		path: 'admin',
		component: LayoutComponent,
		children: [
			{
				path: 'dashboard',
				loadChildren: () => import('./pages/admin/dashboard/dashboard.module').then(m => m.DashboardModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'settings',
				loadChildren: () => import('./pages/admin/settings/settings.module').then(m => m.SettingsModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'products',
				loadChildren: () => import('./pages/admin/products/products.module').then(m => m.ProductsModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'manage-products',
				loadChildren: () => import('./pages/admin/products/manage/manage-products.module').then(m => m.ManageProductsModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'orders',
				loadChildren: () => import('./pages/admin/all-orders/all-orders.module').then(m => m.AllOrdersModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'manage-orders',
				loadChildren: () => import('./pages/admin/all-orders/manage/manage-orders.module').then(m => m.ManageOrdersModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'sales-forms',
				loadChildren: () => import('./pages/admin/all-sales-forms/all-sales-forms.module').then(m => m.AllSalesFormsModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'manage-sales-forms',
				loadChildren: () => import('./pages/admin/all-sales-forms/manage/manage-sales-forms.module').then(m => m.ManageSalesFormsModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'banners',
				loadChildren: () => import('./pages/admin/banners/banners.module').then(m => m.BannersModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'manage-banners',
				loadChildren: () => import('./pages/admin/banners/manage/manage-banners.module').then(m => m.ManageBannersModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'categories',
				loadChildren: () => import('./pages/admin/categories/categories.module').then(m => m.CategoriesModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'manage-category',
				loadChildren: () => import('./pages/admin/categories/manage/manage-category.module').then(m => m.ManageCategoryModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'companies',
				loadChildren: () => import('./pages/admin/companies/companies.module').then(m => m.CompaniesModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'manage-company',
				loadChildren: () => import('./pages/admin/companies/manage/manage-company.module').then(m => m.ManageCompanyModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'all-users',
				loadChildren: () => import('./pages/admin/all-users/all-users.module').then(m => m.AllUsersModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'manage-all-users',
				loadChildren: () => import('./pages/admin/all-users/manage/manage-all-users.module').then(m => m.ManageAllUsersModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'all-news',
				loadChildren: () => import('./pages/admin/all-news/all-news.module').then(m => m.AllNewsModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'all-news/manage',
				loadChildren: () => import('./pages/admin/all-news/manage/manage-news.module').then(m => m.ManageNewsModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'all-videos',
				loadChildren: () => import('./pages/admin/all-videos/all-videos.module').then(m => m.AllVideosModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'all-videos/manage',
				loadChildren: () => import('./pages/admin/all-videos/manage/manage-videos.module').then(m => m.ManageVideosModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'sku-code',
				loadChildren: () => import('./pages/admin/sku-code/sku-code.module').then(m => m.SkuCodeModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'all-pages',
				loadChildren: () => import('./pages/admin/all-pages/all-pages.module').then(m => m.AllPagesModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'all-pages/manage',
				loadChildren: () => import('./pages/admin/all-pages/manage/manage-pages.module').then(m => m.ManagePagesModule),
			},
			{
				path: 'all-supports',
				loadChildren: () => import('./pages/admin/all-supports/all-supports.module').then(m => m.AllSupportsModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'all-supports/manage',
				loadChildren: () => import('./pages/admin/all-supports/manage/manage-support.module').then(m => m.ManageSupportModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'support-subject',
				loadChildren: () => import('./pages/admin/support-subject/support-subject.module').then(m => m.SupportSubjectModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'support-subject/manage',
				loadChildren: () => import('./pages/admin/support-subject/manage/manage-support-subject.module').then(m => m.ManageSupportSubjectModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'all-notifications',
				loadChildren: () => import('./pages/admin/all-notifications/all-notifications.module').then(m => m.AllNotificationsModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'all-notifications/manage',
				loadChildren: () => import('./pages/admin/all-notifications/manage/manage-notification.module').then(m => m.ManageNotificationModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'send-mail',
				loadChildren: () => import('./pages/admin/send-mail/send-mail.module').then(m => m.SendMailModule),
				canActivate: [AuthGuard]
			},
			{
				path: 'new-supports',
				loadChildren: () => import('./pages/admin/new-supports/new-supports.module').then(m => m.NewSupportsModule),
				canActivate: [AuthGuard]
			},
		]
	},
	{
		path: '',
		component: AuthComponent,
		children: [
			{
				path: 'login',
				loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
			},
		]
	},
	{
		path: '**',
		redirectTo: 'dashboard'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
