<ng-container *ngIf="menus">
  <!-- begin::Header Nav -->
  <!-- <ul class="menu-nav" [ngClass]="ulCSSClasses">
    <ng-container *ngFor="let item of menus; let i = index;">
      <ng-container *ngIf="item.main_state && 4 > i" [ngTemplateOutlet]="menu1LevelTemplate"
        [ngTemplateOutletContext]="{ item: item }"></ng-container>
    </ng-container>
    <li class="menu-item menu-item-submenu menu-item-rel" routerLinkActive="menu-item-active"
      [attr.data-menu-toggle]="headerMenuDesktopToggle" aria-haspopup="true">
      <a class="menu-link menu-toggle" routerLinkActive="active">
        <span class="menu-text">{{util.getString('More')}}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-fixed menu-submenu-left" style="width: '1000px'">
        <div class="menu-subnav">
          <ul class="menu-content">
            <li class="menu-item">
              <ul class="menu-inner">
                <ng-container *ngFor="let item of menus; let i = index;">
                  <ng-container *ngIf="item.main_state && i >= 4 && i < 10" [ngTemplateOutlet]="menu2LevelTemplate"
                    [ngTemplateOutletContext]="{ item: item }"></ng-container>
                </ng-container>
              </ul>
            </li>
            <li class="menu-item">
              <ul class="menu-inner">
                <ng-container *ngFor="let item of menus; let i = index;">
                  <ng-container *ngIf="item.main_state && i >= 10 && i < 16" [ngTemplateOutlet]="menu2LevelTemplate"
                    [ngTemplateOutletContext]="{ item: item }"></ng-container>
                </ng-container>
              </ul>
            </li>
            <li class="menu-item">
              <ul class="menu-inner">
                <ng-container *ngFor="let item of menus; let i = index;">
                  <ng-container *ngIf="item.main_state && i >= 16 && i < 22" [ngTemplateOutlet]="menu2LevelTemplate"
                    [ngTemplateOutletContext]="{ item: item }"></ng-container>
                </ng-container>
              </ul>
            </li>
            <li class="menu-item">
              <ul class="menu-inner">
                <ng-container *ngFor="let item of menus; let i = index;">
                  <ng-container *ngIf="item.main_state && i >= 22 && i < 28" [ngTemplateOutlet]="menu2LevelTemplate"
                    [ngTemplateOutletContext]="{ item: item }"></ng-container>
                </ng-container>
              </ul>
            </li>
            <li class="menu-item">
              <ul class="menu-inner">
                <ng-container *ngFor="let item of menus; let i = index;">
                  <ng-container *ngIf="item.main_state && i >= 28 && i < 34" [ngTemplateOutlet]="menu2LevelTemplate"
                    [ngTemplateOutletContext]="{ item: item }"></ng-container>
                </ng-container>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </li>
  </ul> -->
  <!-- end::Header Nav -->
</ng-container>

<!-- begin::1 Level -->
<ng-template #menu1LevelTemplate let-item="item">
  <!-- begin::if don't have submenu -->
  <ng-container *ngIf="item.main_state">
    <!-- begin::1 Level -->
    <li class="menu-item menu-item-rel" routerLinkActive="menu-item-active">
      <a class="menu-link" [routerLink]="['/', item.main_state, item.state]" routerLinkActive="active">
        <span class="menu-text">{{ util.getString(item.name) }}</span>
        <ng-container *ngIf="rootArrowEnabled">
          <i class="menu-arrow"></i>
        </ng-container>
      </a>
    </li>
    <!-- end::1 Level -->
  </ng-container>
  <!-- end::if don't have submenu -->
</ng-template>

<!-- begin::2 Level -->
<ng-template #menu2LevelTemplate let-item="item">
  <!-- begin::if don't have submenu -->
  <ng-container *ngIf="item.main_state">
    <!-- begin::1 Level -->
    <li class="menu-item" routerLinkActive="menu-item-active">
      <a class="menu-link" [routerLink]="['/', item.main_state, item.state]" routerLinkActive="active">
        <span class="menu-text">{{ util.getString(item.name) }}</span>
      </a>
    </li>
    <!-- end::1 Level -->
  </ng-container>
  <!-- end::if has submenu -->
</ng-template>
<!-- end::2 Level -->